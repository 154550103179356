<template>
<header v-bind:class="'context_' + getContext()">
  <div class="inner-wrap">
    <img v-bind:src="require('../assets/logo-psg.svg')">
    <h1>CO<sub>2</sub> Rechner</h1>
  </div>
</header>
<div class="calculator" v-bind:class="'context_' + getContext()">

  <form v-on:submit.prevent="update">
    <div class="form-group" v-if="products.length > 1">
      <label for="product">Produkt</label>
      <div class="select-wrap">
        <div class="selected-product" v-on:click="toggleSelect()">{{ selectedProduct }}
          <span>
            <svg width="32px" height="19px" viewBox="0 0 32 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="square">
                    <g id="iPad-10.2”-Copy" transform="translate(-633, -377)" stroke="#0575A4" stroke-width="5">
                        <g id="Group-3" transform="translate(637, 381)">
                            <line x1="0.333333333" y1="0.34375" x2="11.6666667" y2="10.65625" id="Line-2"></line>
                            <line x1="12.3333333" y1="0.34375" x2="23.6666667" y2="10.65625" id="Line-2-Copy" transform="translate(18, 5.5) scale(-1, 1) translate(-18, -5.5)"></line>
                        </g>
                    </g>
                </g>
            </svg>
          </span>
        </div>
        <ul v-bind:class="selectOpen ? 'open' : ''">
          <li v-on:click="selectProduct(product.label)" v-for="product in products" v-bind:key="product.label">{{ product.label }}</li>
        </ul>
      </div>
    </div>

    <div class="form-group" v-if="products.length == 1">
      <h2>{{ products[0].label }}</h2>
    </div>

    <div class="form-group">
      <label for="amount">Bedarfsmenge</label>
      <div class="form-group__inner-wrap">
        <button v-on:click.prevent="decreaseAmount">-</button>
        <input type="number" v-model="selectedAmount" class="calculator__amount" @change="this.calculateValues()"/>
        <span class="calculator__label">{{ currentLabel }}</span>
        <button v-on:click.prevent="increaseAmount">+</button>
      </div>
    </div>

    <div class="form-group" v-if="selectedProduct.length && calculatedValues.length == 0">
      <button class="calculate" v-on:click.prevent="calculateValues">CO<sub>2</sub> Verbrauch berechnen</button>
    </div>
  </form>

  <div v-if="calculatedValues.length > 0" class="result">
    <h2>CO<sub>2</sub> Verbrauch für Produktion und Lieferung von {{ numberFormat(selectedAmount, 0) }} {{ addLabel(selectedAmount) }}</h2>
    <div class="chart">
      <div class="scale-line" style="left: 20%;"></div>
      <div class="scale-line" style="left: 40%;"></div>
      <div class="scale-line" style="left: 60%;"></div>
      <div class="scale-line" style="left: 80%;"></div>
      <div class="scale-line" style="left: 100%;"></div>

      <div v-for="product in this.calculatedValues" v-bind:key="product.id" v-bind:class="'bar-wrap' + (product.mainProduct ? ' bar-wrap--main' : '')">
        <span class="label">{{ product.info }} {{ product.sumDisplay }} {{ addLabel(product.sum) }} <b v-if="product.relAbs > 0">({{ product.relAbsDisplay }} {{ addLabel(product.relAbs) }} Ersparnis = {{ product.rel }}%)</b></span>
        <span class="bar" v-bind:style="'width: ' + product.percent + '%'"></span>
      </div>

      <div class="scale-text" style="left: 19%;">20%</div>
      <div class="scale-text" style="left: 39%;">40%</div>
      <div class="scale-text" style="left: 59%;">60%</div>
      <div class="scale-text" style="left: 79%;">80%</div>
      <div class="scale-text" style="left: 99%;">100%</div>
    </div>

    <div v-if="calculatedCompare.length" class="calculated-compare">
      <h3>Vergleichswerte</h3>
      <ul>
        <li v-for="compare in this.calculatedCompare" v-bind:key="compare.id"><b>{{ compare.label }}</b>: {{ compare.info }}</li>
      </ul>
    </div>

  </div>
</div>
</template>

<script>

export default {
  name: 'Co2Calculator',

  data() {
    return {
      nonce: '',
      selectOpen: false,
      selectedProduct: '',
      selectedAmount: 1,
      currentLabel: 'Tonne',
      calculatedValues: [],
      calculatedCompare: [],
      products: []
    }
  },

  created: function() {
    this.nonce = window.nonce;
    this.get();
  },

  unmounted: function() {
  },

  methods: {
    get() {
      let uri = '/wp-json/co2_api_fe/v1/data';
      this.axios.get(uri, {headers: {"X-WP-Nonce" : this.nonce}}).
      then((response) => {
        this.products = response.data.products;
        this.compareProducts = response.data.compareProducts;
        if (this.products.length == 1) {
          this.selectedProduct = this.products[0].label;
        }
      });
    },

    numberFormat(value, digits)
    {
      return new Intl.NumberFormat('de-DE', {style: 'decimal', roundingMode: 'floor', maximumFractionDigits: digits}).format(value);
    },


    toggleSelect()
    {
      this.selectOpen = !this.selectOpen;
    },

    selectProduct(product)
    {
      this.selectedProduct = product;
      this.selectOpen = false;
      if (this.calculatedValues.length)
        this.calculateValues();
    },

    checkLabel()
    {
      this.currentLabel = (this.selectedAmount > 1) ? 'Tonnen' : 'Tonne';
    },

    addLabel(value)
    {
      return value > 1 ? 'Tonnen' : 'Tonne';
    },

    increaseAmount()
    {
      this.selectedAmount++;
      if (this.selectedAmount > 1000000) this.selectedAmount = 1000000;
      this.checkLabel();
      if (this.calculatedValues.length)
        this.calculateValues();
    },

    decreaseAmount()
    {
      this.selectedAmount--;
      if (this.selectedAmount < 1) this.selectedAmount = 1;
      this.checkLabel();
      if (this.calculatedValues.length)
        this.calculateValues();
    },

    calculateValues()
    {
      let product = this.getSelectedProduct();
      this.calculatedValues = [];
      this.checkLabel();

      if (product) {
        let baseProduct = {
          label: product.label,
          info: product.info,
          factor: product.factor,
          sum: this.selectedAmount * product.factor,
          sumDisplay: this.numberFormat(this.selectedAmount * product.factor, 2),
          rel: 0,
          relAbs: 0,
          relAbsDisplay: 0,
          percent: 0,
          mainProduct: true
        }
        this.calculatedValues.push(baseProduct);
        for(let i=0; i < product.alternatives.length; i++) {
          product.alternatives[i].sum = this.selectedAmount * product.alternatives[i].factor;
          product.alternatives[i].rel = 100 - Math.round(product.alternatives[i].factor / baseProduct.factor * 100);
          product.alternatives[i].relAbs = baseProduct.sum - product.alternatives[i].sum;
          product.alternatives[i].relAbsDisplay = this.numberFormat(baseProduct.sum - product.alternatives[i].sum, 2);
          product.alternatives[i].sumDisplay = this.numberFormat(product.alternatives[i].sum, 2);
          product.alternatives[i].mainProduct = false;
          this.calculatedValues.push(product.alternatives[i]);
        }

        this.normalizeCaltulatedValues();



        if (this.compareProducts.length && product.alternatives.length) {
          this.calculatedCompare = [];
          for(let i=0; i < this.compareProducts.length; i++) {
            let res = this.numberFormat(product.alternatives[0].relAbs / this.compareProducts[i].factor, 0);
            let info = this.compareProducts[i].info.replace('#SUM#', res);
            this.calculatedCompare.push({
              id: i,
              label: this.compareProducts[i].label,
              info: info
            });
          }
        }

      } else {
        this.calculatedValues = [];
      }
    },

    normalizeCaltulatedValues()
    {
      let max = 0;
      for(let i=0; i < this.calculatedValues.length; i++) {
        if (this.calculatedValues[i].sum > max) {
          max = this.calculatedValues[i].sum;
        }
      }

      for(let i=0; i < this.calculatedValues.length; i++) {
        this.calculatedValues[i].percent = this.calculatedValues[i].sum / max * 100;
      }
    },

    getSelectedProduct()
    {

      for(let i=0; i < this.products.length; i++) {
        if (this.products[i].label == this.selectedProduct) {
          return this.products[i];
        }
      }
      return false;
    },

    getContext()
    {
      if ('undefined' != typeof window.co2calculator_context) {
          return window.co2calculator_context == 'website' ? 'website' : 'webapp';
      }
      return window.location.hash == '#website' ? 'website' : 'webapp';
    }

  }
}
</script>

<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

header {
  position: fixed;
  left:0;
  right: 0;
  top:0;
  height: 130px;
  background: #fff;
  font-family: 'Open Sans', 'Arial';
  z-index: 10;

  .inner-wrap {
    margin: 20px auto;
    width: 800px;
    height: 90px;
    max-width: 100%;
  }

  img {
    height: 90px;
    width: auto;
  }

  h1 {
    font-size: 50px;
    color: #0a6aa1;
    float: right;
    margin-top: 20px;
    margin-bottom: 0;
    sub {
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 800px) {
  header {
    height: 90px;

    .inner-wrap {
      max-width: 90%;
      height: 50px;
    }
  h1 {
    font-size: 30px;
    margin-top: 10px;
    sub {
      font-size: 20px;
    }
  }

  img {
      height: 50px;
    }
  }
}

header.context_website {
  display: none;
}

.calculator {
  padding: 180px 50px 50px 50px;
  text-align: center;
  color: #fff;
  font-family: 'Open Sans', 'Arial';
  background: #0575A4;
  min-height: 100vh;
}

.calculator.context_website {
  padding-top: 50px;
}

.calculator h2 {
  font-size: 30px;
}

.calculator__intro {
  margin: 0 auto 20px auto;
  width: 800px;
  max-width: 100%;
}

.calculator__amount {
  display: inline-block;
  height: 40px;
  width: 120px;
  background: #fff;
  font-size: 28px;
  font-weight: 700;
  color: #0575A4;
  margin-left:5px;
  border:0;
  padding: 0 10px;
  text-align: right;
}

.calculator__label {
  height: 40px;
  width: 120px;
  background: #fff;
  font-size: 28px;
  font-weight: 700;
  color: #0575A4;
  margin-right: 5px;
  text-align: left;
}

.form-group {
  margin-bottom: 30px;
}

.form-group label {
  display: block;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 10px;
}

.form-group__inner-wrap {
  display: flex;
  justify-content: center;
}

button {
  border: none;
  height: 40px;
  min-width: 40px;
  background: #C4DFEA;
  font-size: 28px;
  font-weight: 700;
  color: #0575A4;
}

button:hover {
  cursor: pointer;
}

button.calculate {
  padding: 10px 30px 7px 30px;
  height: auto;
}

button.calculate sub {
  font-size: 20px;
}

.select-wrap {
  position:relative;
  display: block;
  margin: 0 auto;
  width: 290px;
}

.selected-product {
  height: 40px;
  background: #fff;
  font-size: 28px;
  font-weight: 700;
  color: #0575A4;
}

.selected-product:hover {
  cursor: pointer;
}

.selected-product span {
  float:right;
  display: inline-block;
  height: 40px;
  width: 40px;
  background: #C4DFEA;
  border-left: 5px solid #0575A4;
}

.selected-product span svg {
  width: 24px;
  height: auto;
}

.select-wrap ul {
  position: absolute;
  display: none;
  margin: 0 auto;
  padding: 0;
  width: 245px;
}

.select-wrap ul.open {
  display: block;
}

.select-wrap li {
  display: block;
  height: 40px;
  padding: 0 10px;
  background: #efefef;
  text-align: left;
  font-size: 28px;
  color: #0575A4;
  border-top: 1px solid #aaa;
}

.select-wrap li:hover {
  cursor: pointer;
}

.calculated-compare {
  width: 800px;
  max-width: 100%;
  margin: 0 auto ;


  ul {
    text-align: left;
  }

}

.chart {
  width: 800px;
  max-width: 100%;
  margin: 0 auto 50px auto;
  padding: 20px 0 0 0;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  position: relative;
}

.bar-wrap {
  margin: 30px 0;
  position: relative;
}

.bar-wrap .bar {
  display: block;
  height: 25px;
  background-color: #fff;
  opacity: 0.8;
}

.bar-wrap .label {
  display: block;
  text-align: left;
  padding-left: 20px;
  font-size: 20px;
  color: #fff;
}

.bar-wrap .label b {
  font-weight: normal;
}

.scale-line {
  width: 1px;
  height: 30px;
  background-color: #fff;
  position: absolute;
  bottom: -15px;
}

.scale-text {
  position: absolute;
  bottom: -35px;
  font-size: 12px;
}

</style>
